// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-call-components-call-us-main-js": () => import("./../../../src/pages/call/components/CallUsMain.js" /* webpackChunkName: "component---src-pages-call-components-call-us-main-js" */),
  "component---src-pages-call-index-js": () => import("./../../../src/pages/call/index.js" /* webpackChunkName: "component---src-pages-call-index-js" */),
  "component---src-pages-guide-components-content-js": () => import("./../../../src/pages/guide/components/Content.js" /* webpackChunkName: "component---src-pages-guide-components-content-js" */),
  "component---src-pages-guide-components-guide-main-js": () => import("./../../../src/pages/guide/components/GuideMain.js" /* webpackChunkName: "component---src-pages-guide-components-guide-main-js" */),
  "component---src-pages-guide-components-menu-js": () => import("./../../../src/pages/guide/components/Menu.js" /* webpackChunkName: "component---src-pages-guide-components-menu-js" */),
  "component---src-pages-guide-index-js": () => import("./../../../src/pages/guide/index.js" /* webpackChunkName: "component---src-pages-guide-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-print-components-print-main-js": () => import("./../../../src/pages/print/components/PrintMain.js" /* webpackChunkName: "component---src-pages-print-components-print-main-js" */),
  "component---src-pages-print-index-js": () => import("./../../../src/pages/print/index.js" /* webpackChunkName: "component---src-pages-print-index-js" */),
  "component---src-pages-register-components-form-js": () => import("./../../../src/pages/register/components/Form.js" /* webpackChunkName: "component---src-pages-register-components-form-js" */),
  "component---src-pages-register-components-form-main-js": () => import("./../../../src/pages/register/components/FormMain.js" /* webpackChunkName: "component---src-pages-register-components-form-main-js" */),
  "component---src-pages-register-components-my-enroll-js": () => import("./../../../src/pages/register/components/MyEnroll.js" /* webpackChunkName: "component---src-pages-register-components-my-enroll-js" */),
  "component---src-pages-register-components-success-js": () => import("./../../../src/pages/register/components/Success.js" /* webpackChunkName: "component---src-pages-register-components-success-js" */),
  "component---src-pages-register-confirm-index-js": () => import("./../../../src/pages/register/confirm/index.js" /* webpackChunkName: "component---src-pages-register-confirm-index-js" */),
  "component---src-pages-register-enroll-2-index-js": () => import("./../../../src/pages/register/enroll2/index.js" /* webpackChunkName: "component---src-pages-register-enroll-2-index-js" */),
  "component---src-pages-register-enroll-index-js": () => import("./../../../src/pages/register/enroll/index.js" /* webpackChunkName: "component---src-pages-register-enroll-index-js" */),
  "component---src-pages-register-forget-index-js": () => import("./../../../src/pages/register/forget/index.js" /* webpackChunkName: "component---src-pages-register-forget-index-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-register-my-enroll-index-js": () => import("./../../../src/pages/register/myEnroll/index.js" /* webpackChunkName: "component---src-pages-register-my-enroll-index-js" */),
  "component---src-pages-register-success-index-js": () => import("./../../../src/pages/register/success/index.js" /* webpackChunkName: "component---src-pages-register-success-index-js" */),
  "component---src-pages-works-components-works-main-js": () => import("./../../../src/pages/works/components/WorksMain.js" /* webpackChunkName: "component---src-pages-works-components-works-main-js" */),
  "component---src-pages-works-index-js": () => import("./../../../src/pages/works/index.js" /* webpackChunkName: "component---src-pages-works-index-js" */)
}

